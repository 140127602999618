import { userConstants } from '../_constants'
import {FAILED, REQUESTED, SUCCEEDED} from '../helpers/constants.helper'

const initialState = {}

export function authentication(state = initialState, action) {
	switch (action.type) {
		case userConstants.SOCIAL_LOGIN[REQUESTED]:
		case userConstants.LOGIN[REQUESTED]:
			return {
				loggingIn: true,
				socialLoggingIn: {
					...(state.socialLoggingIn || {}),
					...(action.provider ? {[action.provider]: true} : {})
				},
				user: action.user
			}
		case userConstants.SOCIAL_LOGIN[SUCCEEDED]:
		case userConstants.LOGIN[SUCCEEDED]:
			return {
				loggedIn: true,
				user: action.user
			}
		case userConstants.SOCIAL_LOGIN[FAILED]:
		case userConstants.LOGIN[FAILED]:
			return {
				error: action.error
			}
		case userConstants.UPDATE_USER:
			return {
				...state,
				user: {
					...state.user,
					...action.user
				}
			}
		case userConstants.LOGOUT:
			return {}
		default:
			return state
	}
}
