import {authHeader, callApi} from './api.service'
import qs from 'query-string'
import store from '../helpers/store'

const getToken = () => {
	const {cart} = store.getState()

	return cart.order_token
}

export const getOrder = (id) =>
	callApi(`/orders/${id}`, {
		method: 'GET',
		headers: authHeader()
	})

export const createOrder = (body) =>
	callApi('/orders', {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const updateOrder = (order_id, body) =>
	callApi(`/orders/${order_id}${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const chooseOrderPayment = (order_id, body) =>
	callApi(`/orders/${order_id}/payment/choose`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const validateOrderPayment = (order_id, body = undefined) =>
	callApi(`/orders/${order_id}/payment/validate`, {
		method: 'POST',
		headers: authHeader(),
		...(body ? {body: JSON.stringify(body)} : {})
	})

export const getPaymentMethodNonce = (order_id, body = undefined) =>
	callApi(`/orders/${order_id}/payment/method?${qs.stringify(body)}`, {
		method: 'GET',
		headers: authHeader()
	})

export const updateOrderAddress = (order_id, body) =>
	callApi(`/orders/${order_id}/set/address${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const updateOrderBilling = (order_id, body) =>
	callApi(`/orders/${order_id}/set/billing${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const updateOrderDiscount = (order_id, discount) =>
	callApi(`/orders/${order_id}/set/discount${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({discount})
	}, {errorDetailed: true})

export const updateOrderLines = (order_id, body) =>
	callApi(`/orders/${order_id}/set/lines${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const updateOrderObservations = (order_id, body) =>
	callApi(`/orders/${order_id}/set/obs${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const updateOrderTime = (order_id, body) =>
	callApi(`/orders/${order_id}/set/time${getToken() ? '?' + qs.stringify({token: getToken()}) : ''}`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})

export const verifyDiscount = (code, total_price) =>
	callApi(`/discounts/${code}/verify${total_price ? '?' + qs.stringify({total_price}) : ''}`)

export const cancelOrder = (order_id) =>
	callApi(`/orders/${order_id}/cancel`, {
		method: 'POST',
		headers: authHeader()
	})

export const scoreOrder = (order_id, body) =>
	callApi(`/orders/${order_id}/score`, {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body)
	})