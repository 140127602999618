import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import { Link } from './components/Utilities/preloading'
import CookiesConsent from 'react-cookie-consent'
import Header from './components/Header'
import Footer from './components/Footer'
import AppRoutes from './AppRoutes'
import DevTools from './components/DevTools'
import ScrollToTop from './components/ScrollToTop'
import store from './helpers/store'
import { userService } from './_services'
import { userConstants } from './_constants'
import { isAuthenticated } from './_services/authentication.service'
import Alert from './components/Alert'
import EmptyCartConfirmation from './components/Modals/EmptyCartConfirmation'
import { Fallback } from './components/Utilities/preloading'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'

import 'pure-react-carousel/dist/react-carousel.es.css'
import './css/nprogress.css'

NProgress.configure({ showSpinner: false })

const App = () => {
    const { t } = useTranslation(undefined, { useSuspense: false })

    React.useEffect(() => {
        if (!store.error) {
            const { authentication } = store.getState()

            if (isAuthenticated(authentication)) {
                userService
                    .getUser()
                    .then(user =>
                        store.dispatch({
                            type: userConstants.UPDATE_USER,
                            user,
                        })
                    )
                    .catch(() => {
                        /* ignore */
                    })
            }
        }
    }, [])

    return (
        <BrowserRouter basename='/sendeat'>
            <Provider store={store}>
                <div className="body tracking-wide">
                    <Router>
                        <ScrollToTop />
                        <Header />
                        <div className="flex flex-col min-h-screen pt-16">
                            <React.Suspense fallback={<Fallback />}>
                                <AppRoutes />
                            </React.Suspense>
                            <Alert />
                            {/* confirmation modal to empty cart */}
                            <EmptyCartConfirmation />
                        </div>
                        <Footer />
                        <CookiesConsent
                            disableStyles={true}
                            containerClasses="flex flex-wrap justify-between items-center fixed right-0 left-0 bg-purple-500 text-white p-4"
                            buttonClasses="btn btn-orange mt-2 sm:mt-0"
                            buttonText="OK!"
                        >
                            {t('cookies.description')}
                            <Link
                                to="/terms"
                                className="text-gray-500 underline ml-2"
                            >
                                {t('cookies.more_info')}
                            </Link>
                        </CookiesConsent>
                    </Router>
                    {process.env.NODE_ENV === 'production' ? null : <DevTools />}
                </div>
            </Provider>
        </BrowserRouter>
    )
}

export default App
