export const REQUESTED = 'REQUESTED'
export const SUCCEEDED = 'SUCCEEDED'
export const FAILED = 'FAILED'

export const createRequestTypes = (base) => {
	return [REQUESTED, SUCCEEDED, FAILED].reduce((acc, type) => {
		acc[type] = `${base}_${type}`
		return acc
	}, {})
}

export const getBase = (requestType) => {
	return [REQUESTED, SUCCEEDED, FAILED].reduce((acc, type) => {
		return acc.replace(`_${type}`, '')
	}, requestType)
}

