import {delay, put, takeLatest} from 'redux-saga/effects'
import {alertConstants} from '../_constants'

function* alertSaga({timeout = 2500}) {
	yield delay(timeout)
	yield put({type: alertConstants.CLEAR})
}

/*
 *	--------------
 *	-- Watchers --
 *	--------------
 */

export function* watchAlert() {
	yield takeLatest([alertConstants.ERROR, alertConstants.SUCCESS], alertSaga)
}
