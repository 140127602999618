import { orderingConstants } from '../_constants'
import {FAILED, REQUESTED, SUCCEEDED} from '../helpers/constants.helper'
import {formatSearchAddress} from '../helpers/address.helper'

const increaseLoading = (loading) => loading + 1
const decreaseLoading = (loading) => loading > 0 ? loading - 1 : 0

export const initialState = {
	loading: 0,
	search_address: '',
	address: {street: ''},
	location: {},
	search_type: null,
	restaurants: [],
	nicknames: {},
	tags: [],
	meals: {},
	supplements: {},
	market: null,
	categories: [],
	schedules: {},
	group_zone_info: null,
	restaurants_search_error: null,
	markets_search_error: null
}

export function ordering(state = initialState, action) {
	let restaurants = []

	switch (action.type) {
		case orderingConstants.RESTAURANTS[REQUESTED]:
		case orderingConstants.RESTAURANT[REQUESTED]:
		case orderingConstants.MEALS[REQUESTED]:
		case orderingConstants.SUPPLEMENTS[REQUESTED]:
		case orderingConstants.SCHEDULES[REQUESTED]:
		case orderingConstants.CATEGORIES[REQUESTED]:
			return {
				...state,
				loading: increaseLoading(state.loading)
			}
		case orderingConstants.MARKETS[REQUESTED]:
			return {
				...state,
				loading: increaseLoading(state.loading),
				market: null
			}
		case orderingConstants.RESTAURANTS[SUCCEEDED]:
			return {
				...state,
				loading: decreaseLoading(state.loading),
				location: action.location,
				restaurants: action.restaurants,
				tags: action.tags,
				nicknames: action.nicknames,
				group_zone_info: action.group_zone_info,
				restaurants_search_error: null
			}
		case orderingConstants.RESTAURANT[SUCCEEDED]:
			restaurants = state.restaurants.filter(restaurant => restaurant.id === action.restaurant.id).length > 0 ?
				state.restaurants.map(restaurant => restaurant.id === action.restaurant.id ? {...action.restaurant} : restaurant) :
				[...state.restaurants, {...action.restaurant}]

			return {
				...state,
				loading: decreaseLoading(state.loading),
				restaurants,
				tags: [...state.tags.filter(tag => !action.restaurant.tags.includes(tag)), ...action.restaurant.tags],
				nicknames: {...state.nicknames, [action.restaurant.nickname]: restaurants.map(restaurant => restaurant.id).indexOf(action.restaurant.id)}
			}
		case orderingConstants.MEALS[SUCCEEDED]:
			return {
				...state,
				loading: decreaseLoading(state.loading),
				meals: {...state.meals, [action.partner_id]: action.meals}
			}
		case orderingConstants.SUPPLEMENTS[SUCCEEDED]:
			return {
				...state,
				loading: decreaseLoading(state.loading),
				supplements: {...state.supplements, [action.meal_id]: action.supplements}
			}
		case orderingConstants.SCHEDULES[SUCCEEDED]:
			return {
				...state,
				loading: decreaseLoading(state.loading),
				schedules: {...state.schedules, [action.partner_id]: action.schedules}
			}
		case orderingConstants.MARKETS[SUCCEEDED]:
			return {
				...state,
				loading: decreaseLoading(state.loading),
				location: action.location || state.location,
				market: action.market,
				markets_search_error: null
			}
		case orderingConstants.CATEGORIES[SUCCEEDED]:
			return {
				...state,
				loading: decreaseLoading(state.loading),
				categories: action.categories
			}
		case orderingConstants.RESTAURANTS[FAILED]:
			return {
				...state,
				restaurants_search_error: action.error || null,
				loading: decreaseLoading(state.loading)
			}
		case orderingConstants.MARKETS[FAILED]:
			return {
				...state,
				markets_search_error: action.error || null,
				loading: decreaseLoading(state.loading)
			}
		case orderingConstants.RESTAURANT[FAILED]:
		case orderingConstants.MEALS[FAILED]:
		case orderingConstants.SUPPLEMENTS[FAILED]:
		case orderingConstants.SCHEDULES[FAILED]:
		case orderingConstants.CATEGORIES[FAILED]:
			return {
				...state,
				loading: decreaseLoading(state.loading)
			}
		case orderingConstants.SET_CURRENT_ADDRESS:
			return {
				...state,
				search_address: formatSearchAddress(action.address),
				address: action.address,
				...(formatSearchAddress(action.address) !== state.search_address ? {
					restaurants: initialState.restaurants,
					tags: initialState.tags,
					group_zone_info: initialState.group_zone_info,
					markets: initialState.markets,
					nicknames: initialState.nicknames
				} : {})
			}
		case orderingConstants.SET_SEARCH_TYPE:
			return {
				...state,
				search_type: action.search_type
			}
		default:
			return state
	}
}
