import {cartConstants, userConstants} from '../_constants'
import {SUCCEEDED} from '../helpers/constants.helper'

const initialState= {
	/* order info */
	partner_id: null,
	lines: [],
	rates: [],
	tip: 0,
	eats: 0,
	delivery_date: null,
	observations: '',
	paymentArgs: {},
	paymentMethod: null,
	billing_id: 1,
	voucher: null,
	delivery_address: null,

	/* logical helpers */
	partner_type: null,
	empty_confirmation: null,
	delivery_duration: {},
	waitingPayment: null,
	atmReference: {},
	order_token: null,

	/* visual helpers */
	mounted: false,
	mobileVisible: false,
}

export const makeCustomization = (supplement) => ({
	supplement_id: supplement.id,
	name: supplement.name,
	price: supplement.price
})

export const makeSubstitute = (substitute) => ({
	product_id: substitute.id
})

export const makeProduct = ({product, quantity, supplements = [], substitutes = []}) => ({
	product_id: product.id,
	name: product.name,
	price: product.price,
	qty: quantity,
	sublines: supplements.map(supplement => makeCustomization(supplement)),
	substitutes: substitutes.map(substitute => makeSubstitute(substitute))
})

export function cart(state = initialState, action) {
	switch (action.type) {
		case cartConstants.ADD_LINE[SUCCEEDED]:
			return {
				...state,
				partner_id: action.partner_id,
				partner_type: action.partner_type,
				delivery_duration: action.delivery_duration,
				delivery_date: action.delivery_date
			}
		case cartConstants.REMOVE_LINE:
			return {
				...state,
				lines: state.lines.filter(line => line.id !== action.line_id)
			}
		case cartConstants.UPDATE_LINE:
			return {
				...state,
				lines: state.lines.map(line => line.id === action.line.id ? action.line : line)
			}
		case cartConstants.SET_LINES:
			return {
				...state,
				lines: action.lines
			}
		case cartConstants.SET_ORDER:
			return {
				...state,
				order_id: action.order.public_id ? action.order.id : null,
				order_token: action.order.token,
				...(action.order.public_id ? {
					rates: action.order.rates || [],
					lines: action.order.lines || [],
					voucher: action.order.discount,
					observations: (action.order.obs && action.order.obs.restaurant) || '',
					address_observations: (action.order.obs && action.order.obs.sender) || '',
					delivery_address: action.order.address,
					billing_id: action.order.billing.id,
					tip: action.order.tip,
					eats: action.order.eats
				} : {})
			}
		case userConstants.LOGOUT:
		case cartConstants.RESET_CART:
			return {
				...initialState
			}
		case cartConstants.SHOW_EMPTY_CONFIRMATION:
			return {
				...state,
				empty_confirmation: action.partner_id
			}
		case cartConstants.HIDE_EMPTY_CONFIRMATION:
			return {
				...state,
				empty_confirmation: initialState.empty_confirmation
			}
		case cartConstants.SET_DELIVERY_DATE:
			return {
				...state,
				delivery_date: action.delivery_date
			}
		case cartConstants.SET_OBSERVATIONS:
			return {
				...state,
				observations: action.observations
			}
		case cartConstants.SET_ADDRESS_OBSERVATIONS:
			return {
				...state,
				address_observations: action.observations
			}
		case cartConstants.SET_DELIVERY_ADDRESS:
			return {
				...state,
				delivery_address: action.address
			}
		case cartConstants.SET_COMPONENT_STATE:
			return {
				...state,
				mounted: action.state
			}
		case cartConstants.TOGGLE_VISIBILITY:
			return {
				...state,
				mobileVisible: action.visibility !== undefined ? action.visibility : !state.mobileVisible
			}
		case cartConstants.SET_WAITING_PAYMENT:
			return {
				...state,
				waitingPayment: action.waitingPayment
			}
		case cartConstants.SET_PAYMENT_METHOD:
			return {
				...state,
				paymentMethod: action.paymentMethod
			}
		case cartConstants.SET_PAYMENT_ARGS:
			return {
				...state,
				paymentArgs: action.paymentArgs
			}
		case cartConstants.SET_ATM_REFERENCE:
			return {
				...state,
				atmReference: action.atmReference
			}
		case cartConstants.SET_BILLING_ID:
			return {
				...state,
				billing_id: action.billing_id
			}
		case cartConstants.SET_VOUCHER:
			return {
				...state,
				voucher: action.voucher
			}
		case cartConstants.SET_RATES:
			return {
				...state,
				rates: action.rates
			}
		case cartConstants.SET_TIP:
			return {
				...state,
				tip: action.tip
			}
		case cartConstants.INCREASE_TIP:
			return {
				...state,
				tip: state.tip + 0.5
			}
		case cartConstants.DECREASE_TIP:
			return {
				...state,
				tip: state.tip >= 0.5 ? state.tip - 0.5 : state.tip
			}
		case cartConstants.SET_EATS:
			return {
				...state,
				eats: action.eats
			}
		default:
			return state
	}
}
