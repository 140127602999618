import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

const Alert = ({alert}) => (
	<Fragment>
		{alert.message &&
			<div className="fixed bottom-0 w-full z-50" style={{maxHeight: '52px'}}>
				<div className={`w-ful sm:w-1/2 mx-auto text-white p-3 ${alert.type === 'alert-danger' ? 'bg-red-500' : null} ${alert.type === 'alert-success' ? 'bg-orange-500' : null}`}>
					{alert.message}
				</div>
			</div>
		}
	</Fragment>
)

Alert.propTypes = {
	alert: PropTypes.object
}

export default connect(
	({alert}) => ({alert})
)(Alert)
