import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { isAuthenticated } from './_services/authentication.service'
import routes from './routes'

const PrivateRoute = connect(({ authentication }) => ({
    authentication,
}))(({ component: Component, authentication, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated(authentication) ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
))

export default () => (
    <Switch>
        {routes.map(({ private: isPrivate, ...route }) =>
            !isPrivate ? (
                <Route key={route.path} {...route} />
            ) : (
                <PrivateRoute key={route.path} {...route} />
            )
        )}
    </Switch>
)
