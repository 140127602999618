import logo from '../../svg/logo-positivo.svg'
import React from 'react'
import {Link} from '../Utilities/preloading'
import Menu from './Menu'
import ClientMenu from './ClientMenu'
import { useTranslation} from 'react-i18next'
import CartToggle from './CartToggle'
import moment from 'moment'


const Header = () => {

	const {i18n} = useTranslation(undefined, {useSuspense: false})

	const changeLanguage = lng => {
		i18n.changeLanguage(lng)
		moment.locale(lng)
	}

	return (
		<header className="fixed bg-purple-500 w-full top-0 shadow-md" style={{zIndex: 998}}>
			<div className="wrapper flex justify-between items-stretch">
				<div className="py-5 lg:py-4">
					<Link to="/">
						<img src={logo} className="h-6 lg:h-8" alt="logo"/>
					</Link>
				</div>
				<nav className="flex justify-end items-center">
					<Menu useSuspense={false}/>
					<ClientMenu useSuspense={false} />
					<CartToggle/>
					<div className="locale ml-2 sm:ml-3">
						<span className="cursor-pointer">
							<b className={`text-white border-white hover:border-orange-500 hover:border-b-2 ${i18n.language === 'pt' ? 'border-b-2' : ''}`} onClick={() => changeLanguage('pt')}>PT</b>
						</span>
						<b className="text-white"> | </b>
						<span className="cursor-pointer">
							<b className={`text-white border-white hover:border-orange-500 hover:border-b-2 ${i18n.language === 'en' ? 'border-b-2' : ''}`} onClick={() => changeLanguage('en')}>EN</b>
						</span>
					</div>
				</nav>
			</div>
		</header>
	)
}

export default Header
