import {cartConstants} from '../_constants'

const STORAGE_BUILD = 1
export const expirationTime = 60*60*1000

export const stateTimestamp = () => {
	try {
		const serializedState = localStorage.getItem('state')
		if (serializedState === null) {
			return undefined
		}
		const stateObj = JSON.parse(serializedState)
		return stateObj.timestamp
	} catch (err) {
		return undefined
	}
}

export const loadAuth = () => {
	try {
		const serializedAuth = localStorage.getItem('auth')
		if (serializedAuth === null) {
			return undefined
		}
		const authObj = JSON.parse(serializedAuth)
		if (authObj.user && (new Date()).getTime() - authObj.user.timestamp > authObj.user.expires_in * 1000) {
			return undefined
		}
		return authObj
	} catch (error) {
		return undefined
	}
}

export const loadState = () => {
	let serializedState
	try {
		serializedState = localStorage.getItem('state')
		if (serializedState === null) {
			return undefined
		}
	} catch (err) {
		return undefined
	}
	const stateObj = JSON.parse(serializedState)
	if (stateObj.version < STORAGE_BUILD) {
		try {
			localStorage.removeItem('state')
		} catch (e) { /* ignore */}
		return undefined
	} else if ((new Date()).getTime() - stateObj.timestamp > expirationTime) {
		throw new Error(stateObj.state)
	}
	return stateObj.state
}

export const saveAuth = (auth) => {
	try {
		const serializedAuth = JSON.stringify(auth)
		localStorage.setItem('auth', serializedAuth)
	} catch (e) {/* ignore */}
}

export const saveState = (state) => {
	const now = (new Date()).getTime()
	const prevTimestamp = stateTimestamp()
	try {
		const serializedState = JSON.stringify({state, timestamp: now, version: STORAGE_BUILD})
		localStorage.setItem('state', serializedState)
	} catch (e) {/* ignore */}

	if (now - prevTimestamp > expirationTime)
		throw new Error('Session as expired')
}

export const blacklistProperties = (obj, exclusions) => {

	const properties = Object.keys(exclusions)

	return Object.keys(obj).reduce((object, key) => properties.includes(key) ? exclusions[key] === undefined ? object : {...object, [key]: exclusions[key]} : {...object, [key]: obj[key]}, {})
}

export const handleSessionExpired = (dispatch) => {
	dispatch({type: cartConstants.RESET_CART})
}
