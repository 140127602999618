import { authHeader, callApi } from './api.service'
import qs from 'query-string'

/*
 * User information
 */
export const getUser = (headers = null) =>
    callApi('/user', {
        method: 'GET',
        headers: headers || authHeader(),
    })

export const updateUser = (user, args = {}) =>
    callApi(
        '/user',
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(user),
        },
        args
    )

export const updatePassword = (body, args = {}) =>
    callApi(
        '/user/password',
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(body),
        },
        args
    )

/*
 * Phone confirmation
 */
export const requestPhoneCode = () =>
    callApi('/user/phone/code', { method: 'POST', headers: authHeader() })

export const verifyPhoneConfirmation = () =>
    callApi('/user/phone/confirmation', {
        method: 'GET',
        headers: authHeader(),
    })

export const confirmPhone = code =>
    callApi('/user/phone/confirmation', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            code,
        }),
    })

/*
 * Addresses
 */
export const addAddress = address =>
    callApi('/user/addresses', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(address),
    })

export const updateAddress = (id, address) =>
    callApi(`/user/addresses/${id}`, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(address),
    })

export const getAddresses = () =>
    callApi('/user/addresses', {
        method: 'GET',
        headers: authHeader(),
    })

/*
 * Credit Cards
 */
export const getCards = () =>
    callApi('/user/cards', {
        method: 'GET',
        headers: authHeader(),
    })

export const addCard = body =>
    callApi('/user/cards', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body),
    })

export const removeCard = token =>
    callApi('/user/cards', {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ token }),
    })

/*
 * Billings
 */
export const getBillings = () =>
    callApi('/user/billing', {
        method: 'GET',
        headers: authHeader(),
    })

export const addBilling = body =>
    callApi('/user/billing', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body),
    })

/*
 * User's Orders
 */
export let cancelGetOrder
export const getOrders = (params = null, args = {}) => {
    cancelGetOrder = new AbortController()
    return callApi(
        `/user/orders${params ? `?${qs.stringify(params)}` : ''}`,
        {
            method: 'GET',
            headers: authHeader(),
            signal: cancelGetOrder.signal,
        },
        args
    )
}

/*
 * User's Storefront
 */

export const getStoreFront = address =>
    callApi(`/storefront?address=${address}`, {
        method: 'GET',
        headers: authHeader(),
    })

/*
 * Discounts
 */
export const getVouchers = () =>
    callApi('/user/vouchers', {
        method: 'GET',
        headers: authHeader(),
    })

export const getReferral = () =>
    callApi('/user/referral', {
        method: 'GET',
        headers: authHeader(),
    })

export const getEats = () =>
    callApi('/user/eats', {
        method: 'GET',
        headers: authHeader(),
    })

export const exchangeEats = eats_id =>
    callApi(`/user/eats/${eats_id}/exchange`, {
        method: 'POST',
        headers: authHeader(),
    })

/*
 * Favourites
 */
export const addFavouritePartner = partner_id =>
    callApi('/user/favourite/partners', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            partner_id,
        }),
    })

export const removeFavouritePartner = partner_id =>
    callApi('/user/favourite/partners', {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({
            partner_id,
        }),
    })

export const addFavouriteGoods = goods_id =>
    callApi('/user/favourite/partners', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            goods_id,
        }),
    })

export const removeFavouriteGoods = goods_id =>
    callApi('/user/favourite/partners', {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({
            goods_id,
        }),
    })
