import {put, call, takeLatest, takeEvery} from 'redux-saga/effects'
import {alertConstants, userConstants} from '../_constants'
import {FAILED, REQUESTED, SUCCEEDED} from '../helpers/constants.helper'
import {login, socialLogin} from '../_services/authentication.service'
import {userService} from '../_services'
import {addAddress, updateAddress} from '../_services/user.service'

function* saveAddressSaga({address, onSuccess}) {
	try {
		let _address
		if (!address.id)
			_address = yield call(addAddress, address)
		else
			_address = yield call(updateAddress, address.id, address)

		yield put({type: userConstants.SAVE_ADDRESS[SUCCEEDED], address: _address})
		if (onSuccess)
			onSuccess(_address)

	} catch (error) {
		yield put({type: userConstants.SAVE_ADDRESS[FAILED]})
		yield put({type: alertConstants.ERROR, message: String(error)})
	}
}

function* addressesSaga() {
	try {
		const addresses = yield call(userService.getAddresses)
		yield put({type: userConstants.ADDRESSES[SUCCEEDED], addresses})
	} catch (error) {
		yield put({type: userConstants.ADDRESSES[FAILED]})
		yield put({type:alertConstants.ERROR, message: String(error)})
	}
}

function* cardsSaga() {
	try {
		const cards = yield call(userService.getCards)
		yield put({type: userConstants.CARDS[SUCCEEDED], cards})
	} catch (error) {
		yield put({type: userConstants.CARDS[FAILED]})
		yield put({type: alertConstants.ERROR, message: error.message || String(error)})
	}
}

function* billingsSaga() {
	try {
		const billings = yield call(userService.getBillings)
		yield put({type: userConstants.BILLINGS[SUCCEEDED], billings})
	} catch (error) {
		yield put({type: userConstants.BILLINGS[FAILED]})
		yield put({type: alertConstants.ERROR, message: error.message || String(error)})
	}
}

function* loginSaga(payload) {
	try {
		const user = yield call(login, payload.email, payload.password)
		yield put({type: userConstants.LOGIN[SUCCEEDED], user})
	} catch (error) {
		yield put({type: userConstants.LOGIN[FAILED], error})
	}
}

function* socialLoginSaga({provider, user: userFields, tokens}) {
	try {
		const user = yield call(socialLogin, provider, userFields, tokens)
		yield put({type: userConstants.SOCIAL_LOGIN[SUCCEEDED], user})
	} catch (error) {
		yield put({type: userConstants.SOCIAL_LOGIN[FAILED], error})
	}
}


function* userStoreFrontSaga ({address}) {
	try {
		const store_front = yield call(userService.getStoreFront, address)
		yield put ({type: userConstants.STORE_FRONT[SUCCEEDED], store_front})
	} catch (error) {
		yield put ({type: userConstants.ORDERS[FAILED]})
		yield put ({type: alertConstants.ERROR, message: error.message || String(error)})

	}
}

function* userPhoneVerificationSaga () {
	try {
		const {confirmed} = yield call(userService.verifyPhoneConfirmation)
		yield put({type: userConstants.PHONE_VERIFICATION[SUCCEEDED], confirmed})
	} catch (error) {
		yield put({type: userConstants.PHONE_VERIFICATION[FAILED]})
	}
}

function* vouchersSaga() {
	try {
		const vouchers = yield call(userService.getVouchers)
		yield put({type: userConstants.VOUCHERS[SUCCEEDED], vouchers})
	} catch (error) {
		yield put({type: userConstants.VOUCHERS[FAILED]})
		yield put({type: alertConstants.ERROR, message: error.message || String(error)})
	}
}

function* referralSaga() {
	try {
		const {code} = yield call(userService.getReferral)
		yield put({type: userConstants.REFERRAL[SUCCEEDED], referral: code})
	} catch (error) {
		yield put({type: userConstants.REFERRAL[FAILED]})
		yield put({type: alertConstants.ERROR, message: error.message || String(error)})
	}
}

/*
 *	--------------
 *	-- Watchers --
 *	--------------
 */

export function* watchSaveAddress() {
	yield takeEvery(userConstants.SAVE_ADDRESS[REQUESTED], saveAddressSaga)
}

export function* watchAddressesRequest() { 
	yield takeLatest(userConstants.ADDRESSES[REQUESTED], addressesSaga)
}

export function* watchCardsRequest() {
	yield takeLatest(userConstants.CARDS[REQUESTED], cardsSaga)
}

export function* watchBillingsRequest() {
	yield takeLatest(userConstants.BILLINGS[REQUESTED], billingsSaga)
}

export function* watchLoginRequest() {
	yield takeLatest(userConstants.LOGIN[REQUESTED], loginSaga)
}

export function* watchSocialLoginRequest() {
	yield takeLatest(userConstants.SOCIAL_LOGIN[REQUESTED], socialLoginSaga)
}

export function* watchUserStoreFrontRequest() {
	yield takeEvery(userConstants.STORE_FRONT[REQUESTED], userStoreFrontSaga)
}

export function* watchUserPhoneVerificationRequest() {
	yield takeEvery(userConstants.PHONE_VERIFICATION[REQUESTED], userPhoneVerificationSaga)
}

export function* watchVouchersRequest() {
	yield takeEvery(userConstants.VOUCHERS[REQUESTED], vouchersSaga)
}

export function* watchReferralRequest() {
	yield takeEvery(userConstants.REFERRAL[REQUESTED], referralSaga)
}
