import {callApi, authHeader} from './api.service'
import qs from 'query-string'

/*
 * Markets
 */
export const getMarketById = (id) =>
	callApi(`/markets/${id}`, {method: 'GET'})

export const searchMarkets = (address) =>
	callApi(`/markets/search?${qs.stringify({address})}`, {
		method: 'GET',
		headers: authHeader()
	}, {errorDetailed: true})

export const getMarketCategories = (id) =>
	callApi(`/markets/${id}/categories`, {method: 'GET'})

export const getGoods = (id, args=null) => 
	callApi(`/markets/${id}/goods${args ? `?${qs.stringify(args)}` : ''}`, {method: 'GET'})

export const getSubstitutes = (market_id, goods_id) =>
	callApi(`/markets/${market_id}/goods/${goods_id}/substitutes`, {method: 'GET'})
	
export const getMarketStorefront = (id) =>
	callApi(`/markets/${id}/storefront`, {method: 'GET'})

/*
 * Restaurants
 */
export const searchRestaurants = (address, partner=undefined) =>
	callApi(`/partners/search?${qs.stringify({address, ...(partner ? {partner} : {})})}`, {
		method: 'GET',
		headers: authHeader()
	}, {errorDetailed: true})

export const getRestaurantById = (id) =>
	callApi(`/partners/${id}`, {method: 'GET'})

export const getRestaurantByNickname = (nickname) =>
	callApi(`/partners/nicknameToId?${qs.stringify({nickname})}`, {method: 'GET'})
		.then(response => callApi(`/partners/${response.id}`))

export const getRestaurantMeals = (id) =>
	callApi(`/partners/${id}/meals`, {method: 'GET'})

export const getMealSupplements = (partner_id, meal_id) =>
	callApi(`/partners/${partner_id}/meals/${meal_id}/supplements`, {method: 'GET'})

export const getRestaurantSchedule = (id, location) =>
	callApi(`/partners/${id}/schedule?${qs.stringify(location)}`, {method: 'GET'})
