import {throttle} from 'throttle-debounce'
import {blacklistProperties, handleSessionExpired, saveAuth, saveState} from '../localStorage'

export const persistenceSubscriber = (store) =>
	throttle(500, false,() => {
		// eslint-disable-next-line no-unused-vars
		const {authentication, ordering, cart, user, ...rest} = store.getState()
		const state = blacklistProperties(rest, {alert: undefined})
		try {
			saveState({
				...state,
				ordering: blacklistProperties(ordering, {loading: 0}),
				cart: blacklistProperties(cart, {empty_confirmation: null, mounted: false, mobileVisible: false}),
				user: blacklistProperties(user, {loading: {}})
			})
		} catch (e) {
			handleSessionExpired(store.dispatch)
		}
		saveAuth(blacklistProperties(authentication, {error: null, loggingIn: false}))
	})
