import { userConstants } from '../_constants'
import { put } from 'redux-saga/effects'
import store from '../helpers/store'
import { isAuthenticated } from './authentication.service'
import i18next from 'i18next'

export const authHeader = () => {
    const { authentication } = store.getState()

    if (isAuthenticated(authentication)) {
        return { Authorization: 'Bearer ' + authentication.user.access_token }
    }

    return {}
}

export const handleResponse = options => response =>
    response.text().then(text => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            if (response.status === 401) {
                put({ type: userConstants.LOGOUT })
            }

            const error =
                (data && (data.error || data.message)) || response.statusText

            if (options.errorDetailed) {
                return Promise.reject({
                    status: response.status,
                    data,
                    message: error,
                })
            }

            return Promise.reject(error)
        }

        if (options.responseDetailed) {
            return Promise.resolve({ data, headers: response.headers })
        }
        return Promise.resolve(data)
    })

export const callApi = (path, requestOptions = {}, options = {}) =>
    fetch(
        `${process.env.REACT_APP_API_URL.replace(/\/$/, '')}/${path.replace(
            /^\/+/,
            ''
        )}`,
        {
            ...requestOptions,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18next.language,
                ...(requestOptions.headers ? requestOptions.headers : {}),
            },
        }
    ).then(handleResponse(options))
