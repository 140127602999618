import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {ReactComponent as CartIcon} from '../../svg/cart.svg'
import {cartConstants, orderingConstants} from '../../_constants'
import { goTo } from '../Utilities/preloading'

const CartToggle = ({cart, restaurants, setSearchType, toggleCartVisibility}) => {

	const history = useHistory()

	const handleCartToggle = () => {

		if (!cart.mounted) {
			if (cart.partner_type === orderingConstants.SEARCH_TYPE_RESTAURANTS) {
				const restaurant = restaurants.find(restaurant => restaurant.id === cart.partner_id) || {}
				setSearchType(orderingConstants.SEARCH_TYPE_RESTAURANTS)
				if (restaurant.nickname)
					goTo(history, `/restaurants/${restaurant.nickname}`)
				else {
					goTo(history, '/results')
				}
			} else if (cart.partner_type === orderingConstants.SEARCH_TYPE_MARKETS) {
				setSearchType(orderingConstants.SEARCH_TYPE_MARKETS)
				goTo(history, '/markets')
			}
		}

		toggleCartVisibility()
	}

	return cart.lines.length > 0 ? (
		<div className="relative mr-2" onClick={handleCartToggle}>
			<CartIcon className="text-white stroke-current h-5 cursor-pointer" />
			<div className="absolute top-0 right-0">
				<div className="flex justify-center items-center text-xs bg-orange-500 text-white rounded-full -mt-2 -mr-2 h-4 w-4 cursor-pointer">
					{cart.lines.reduce((sum, line) => sum + line.qty, 0)}
				</div>
			</div>
		</div>
	) : null
}

CartToggle.propTypes = {
	cart: PropTypes.object,
	restaurants: PropTypes.array,
	markets: PropTypes.object,
	toggleCartVisibility: PropTypes.func
}

export default connect(
	({cart, ordering: {restaurants, markets}}) => ({cart, restaurants, markets}),
	dispatch => ({
		toggleCartVisibility: () => dispatch({type: cartConstants.TOGGLE_VISIBILITY}),
		setSearchType: (search_type) => dispatch({type: orderingConstants.SET_SEARCH_TYPE, search_type})
	})
)(CartToggle)
