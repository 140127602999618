import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Modal = ({open = false, onClose, size = 'md', children, displayCloseButton = true, className, containerClassName, smFullPage = true}) => {


	return open ? (
		<div className={classNames('fixed inset-0 overflow-auto bg-smoke-light flex', className)} style={{zIndex: 1000}}>
			{/* purgecss: max-w-xs max-w-sm max-w-md max-w-lg max-w-xl max-w-2xl max-w-3xl max-w-4xl max-w-5xl max-w-6xl */}
			<div className={classNames(`relative bg-white w-full max-w-${size} m-auto flex-col flex overflow-y-auto sm:h-auto`, containerClassName, {'h-full': smFullPage})}>
				{
					displayCloseButton ? (
						<span className="absolute top-0 right-0 m-2 z-20 rounded-full bg-orange-500 hover:bg-orange-300">
							<svg className="h-5 w-5 fill-current text-purple-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={onClose}>
								<title>Close</title>
								<path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
						</span>
					) : null
				}
				{children}
			</div>
		</div>
	) : null
}

export const modalPropTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	displayCloseButton: PropTypes.bool,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl']),
	containerClassName: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	smFullPage: PropTypes.bool
}

Modal.propTypes = modalPropTypes

export default Modal
