import {createRequestTypes} from '../helpers/constants.helper'

export const userConstants = {
	LOGIN: createRequestTypes('USERS_LOGIN'),
	SOCIAL_LOGIN: createRequestTypes('USERS_SOCIAL_LOGIN'),

	PHONE_VERIFICATION: createRequestTypes('USERS_PHONE_VERIFICATION'),

	ADDRESSES: createRequestTypes('USERS_ADDRESSES'),
	SAVE_ADDRESS: createRequestTypes('USERS_SAVE_ADDRESS'),

	CARDS: createRequestTypes('USERS_CARDS'),

	VOUCHERS: createRequestTypes('USER_VOUCHERS'),
	REFERRAL: createRequestTypes('USER_REFERRAL'),

	BILLINGS: createRequestTypes('USERS_BILLINGS'),

	ORDERS: createRequestTypes('USER_ORDERS'),

	STORE_FRONT: createRequestTypes('USER_STORE_FRONT'),
	LOGOUT: 'USERS_LOGOUT',

	UPDATE_USER: 'USERS_SET_USER'
}
