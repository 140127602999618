import { userConstants } from '../_constants'
import {FAILED, getBase, REQUESTED, SUCCEEDED} from '../helpers/constants.helper'

const disableLoading = (loading, action) => ({...loading, [getBase(action.type)]: false})

const initialState = {
	loading: {},
	addresses: [],
	cards: [],
	billings: [],
	vouchers: [],
	store_front: {},
	phone_confirmed: false
}

export function user(state = initialState, action) {
	switch (action.type) {
		case userConstants.ADDRESSES[REQUESTED]:
		case userConstants.SAVE_ADDRESS[REQUESTED]:
		case userConstants.CARDS[REQUESTED]:
		case userConstants.BILLINGS[REQUESTED]:
		case userConstants.STORE_FRONT[REQUESTED]:
		case userConstants.PHONE_VERIFICATION[REQUESTED]:
		case userConstants.VOUCHERS[REQUESTED]:
		case userConstants.REFERRAL[REQUESTED]:
			return {
				...state,
				loading: {
					...state.loading,
					[getBase(action.type)]: true
				}
			}
		case userConstants.ADDRESSES[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				addresses: action.addresses
			}
		case userConstants.CARDS[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				cards: action.cards
			}
		case userConstants.BILLINGS[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				billings: action.billings
			}
		case userConstants.SAVE_ADDRESS[SUCCEEDED]:
			return {
				...state,
				addresses: [...state.addresses.filter(address => address !== action.address.id), action.address.id]
			}
		case userConstants.STORE_FRONT[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				store_front: action.store_front
			}	
		case userConstants.PHONE_VERIFICATION[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				phone_confirmed: action.confirmed,
			}
		case userConstants.VOUCHERS[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				vouchers: action.vouchers
			}
		case userConstants.REFERRAL[SUCCEEDED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				referral: action.referral
			}
		case userConstants.PHONE_VERIFICATION[FAILED]:
			return {
				...state,
				loading: disableLoading(state.loading, action),
				phone_confirmed: false,
			}
		case userConstants.ADDRESSES[FAILED]:
		case userConstants.SAVE_ADDRESS[FAILED]:
		case userConstants.CARDS[FAILED]:
		case userConstants.BILLINGS[FAILED]:
		case userConstants.STORE_FRONT[FAILED]:	
		case userConstants.VOUCHERS[FAILED]:
		case userConstants.REFERRAL[FAILED]:
			return {
				...state,
				loading: disableLoading(state.loading, action)
			}
		case userConstants.LOGOUT:
			return {
				...initialState
			}
		default:
			return state
	}
}
