import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Button = ({
    children,
    className = '',
    color = 'orange',
    disabled = false,
    ...rest
}) => (
    /* purgecss: btn-purple btn-orange btn-red*/
    <button
        {...rest}
        disabled={disabled}
        className={classNames('btn', className, {
            [`btn-${color}`]: !disabled,
            'btn-disabled': disabled,
            'cursor-not-allowed': disabled,
        })}
    >
        {children}
    </button>
)

Button.propTypes = {
    color: PropTypes.oneOf(['purple', 'orange', 'red']),
    disabled: PropTypes.bool,
}

export default Button
