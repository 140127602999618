import React from 'react'

export const ReactLazyPreload = importStatement => {
    const Component = React.lazy(importStatement)
    Component.preload = importStatement
    return Component
}

const Home = ReactLazyPreload(() => import('./components/Home'))
const Register = ReactLazyPreload(() => import('./components/Register'))
const Login = ReactLazyPreload(() => import('./components/Login'))
const RecoverPassword = ReactLazyPreload(() =>
    import('./components/RecoverPassword')
)
const Results = ReactLazyPreload(() => import('./components/Results'))
const About = ReactLazyPreload(() => import('./components/About'))
const Faq = ReactLazyPreload(() => import('./components/Faq'))
const Terms = ReactLazyPreload(() => import('./components/Terms'))
const Senders = ReactLazyPreload(() => import('./components/Senders'))
const Partners = ReactLazyPreload(() => import('./components/Partners'))
const Careers = ReactLazyPreload(() => import('./components/Careers'))
const Contacts = ReactLazyPreload(() => import('./components/Contacts'))
const EatPoints = ReactLazyPreload(() => import('./components/Eats'))
const Restaurant = ReactLazyPreload(() => import('./components/Restaurant'))
const Checkout = ReactLazyPreload(() => import('./components/Checkout'))
const ClientArea = ReactLazyPreload(() => import('./components/ClientArea'))
const Referrals = ReactLazyPreload(() =>
    import('./components/ClientArea/Referrals')
)
const Eats = ReactLazyPreload(() => import('./components/ClientArea/Eats'))
const Orders = ReactLazyPreload(() => import('./components/ClientArea/Orders'))
const PersonalData = ReactLazyPreload(() =>
    import('./components/ClientArea/PersonalData')
)
const ChangePassword = ReactLazyPreload(() =>
    import('./components/ClientArea/ChangePassword')
)
const CreditCards = ReactLazyPreload(() => import('./components/CreditCards'))
const Tracker = ReactLazyPreload(() => import('./components/Tracker'))
const Unsubscribe = ReactLazyPreload(() =>
    import('./components/Mail/Unsubscribe')
)

export default [
    { path: '/', exact: true, component: Home },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/recover-password', component: RecoverPassword },
    { path: '/(results|markets)', component: Results },
    { path: '/about', component: About },
    { path: '/faq', component: Faq },
    { path: '/terms', component: Terms },
    { path: '/senders', component: Senders },
    { path: '/partners', component: Partners },
    { path: '/careers', component: Careers },
    { path: '/contacts', component: Contacts },
    { path: '/eats', component: EatPoints },
    { path: '/restaurants/:nickname', component: Restaurant },
    { path: '/checkout', component: Checkout },
    { path: '/tracker', component: Tracker },
    {
        path: '/client-area/',
        exact: true,
        component: ClientArea,
        private: true,
    },
    { path: '/mail/unsubscribe/:hash', component: Unsubscribe },
    { path: '/client-area/referrals', component: Referrals, private: true },
    { path: '/client-area/eats', component: Eats, private: true },
    { path: '/client-area/orders', component: Orders, private: true },
    {
        path: '/client-area/personal-data',
        component: PersonalData,
        private: true,
    },
    {
        path: '/client-area/change-password',
        component: ChangePassword,
        private: true,
    },
    { path: '/client-area/credit-card', component: CreditCards, private: true },
]
