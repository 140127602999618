import { combineReducers } from 'redux'

import { authentication } from './authentication.reducer'
import { alert } from './alert.reducer'
import { user } from './user.reducer'
import { ordering } from './ordering.reducer'
import { cart } from './cart.reducer'

const rootReducer = combineReducers({
	authentication,
	alert,
	user,
	ordering,
	cart
})

export default rootReducer
