import React from 'react'
import { ReactComponent as FacebookIcon } from '../svg/facebook.svg'
import { ReactComponent as InstagramIcon } from '../svg/instagram.svg'
import { useTranslation } from 'react-i18next'
import { Link } from './Utilities/preloading'

const Footer = () => {
    const { t, i18n } = useTranslation(undefined, { useSuspense: false })

    return (
        <footer className="bg-black text-white">
            <div className="wrapper flex flex-wrap sm:flex-no-wrap justify-center sm:justify-between">
                <div className="flex sm:flex-col py-4">
                    <a
                        href="https://itunes.apple.com/pt/app/sendeat/id1369226616?mt=8"
                        className="h-10 sm:mb-2 mr-2 sm:mr-0"
                    >
                        <img
                            src={
                                i18n.languages && i18n.languages.includes('pt')
                                    ? '/img/appleBadgePT.svg'
                                    : '/img/appleBadgeEN.svg'
                            }
                            alt="Download on App Store"
                            className="h-full w-auto sm:w-full sm:h-auto"
                        />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.sendeat.android"
                        className="h-10"
                    >
                        <img
                            src={
                                i18n.languages && i18n.languages.includes('pt')
                                    ? '/img/googleBadgePT.png'
                                    : '/img/googleBadgeEN.png'
                            }
                            alt="Download on Google Play"
                            className="h-full w-auto sm:w-full sm:h-auto"
                        />
                    </a>
                </div>
                <div className="flex flex-col flex-grow justify-center items-center text-center px-6 mb-3 sm:mb-0">
                    <ul className="mb-2 lg:text-lg font-bold leading-none">
                        <li className="inline-block border-r-2 px-2 mb-1">
                            <Link to="/about" className="hover:text-purple-500">
                                {t('footer.about')}
                            </Link>
                        </li>
                        <li className="inline-block border-r-2 px-2 mb-1">
                            <Link to="/faq" className="hover:text-purple-500">
                                {t('footer.help')}
                            </Link>
                        </li>
                        <li className="inline-block border-r-2 px-2 mb-1">
                            <Link to="/eats" className="hover:text-purple-500">
                                {t('footer.eats')}
                            </Link>
                        </li>
                        <li className="inline-block border-r-2 px-2 mb-1">
                            <Link to="/terms" className="hover:text-purple-500">
                                {t('footer.terms_conditions')}
                            </Link>
                        </li>
                        <li className="inline-block px-2 mb-1">
                            <Link
                                to="/contacts"
                                className="hover:text-purple-500"
                            >
                                {t('footer.contacts')}
                            </Link>
                        </li>
                    </ul>
                    <span className="text-xl font-bold">© Comer em Casa {new Date().getFullYear()} </span>
                </div>
                <div className="flex items-center mb-3 sm:mb-0">
                    <a
                        href="http://www.facebook.com/sendeatpt"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-4"
                    >
                        <FacebookIcon
                            alt="facebook"
                            aria-label="facebook"
                            className="stroke-current"
                        />
                    </a>
                    <a
                        href="http://www.instagram.com/sendeat_pt"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-4"
                    >
                        <InstagramIcon
                            alt="instagram"
                            aria-label="instagram"
                            className="stroke-current"
                        />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
