import React from 'react'
import {ReactComponent as MenuIcon} from '../../svg/heroicons/icon-menu.svg'
import {ReactComponent as FacebookIcon} from '../../svg/facebook.svg'
import {ReactComponent as InstagramIcon} from '../../svg/instagram.svg'
import {Link} from '../Utilities/preloading'
import {withTranslation} from 'react-i18next'

class Menu extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			open: false
		}

		this.handleClick= this.handleClick.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
		this.closeMenu = this.closeMenu.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside, false)
	}

	handleClick() {
		const {open} = this.state
		this.setState({open: !open})
	}

	handleClickOutside(e) {
		const {open} = this.state

		if (open) {
			if (!this.node.contains(e.target))
				this.setState({open: false})
		}
	}

	closeMenu() {
		this.setState({open: false})
	}

	render() {

		const {open} = this.state
		const {t} = this.props

		return (
			<div className="h-full" ref={node => this.node = node}>
				<div className="flex items-center h-full">
					<span className={`cursor-pointer text-white hover:text-orange-500 flex mx-1 sm:mx-3`} onClick={this.handleClick}>
						<MenuIcon className="fill-current h-5 sm:h-6 mr-1" />
						<b className="hidden md:block">Menu</b>
					</span>
				</div>
				<div className="relative">
					{
						open ? (
							<div className="absolute top-auto bg-purple-500 text-white p-3 shadow min-w-40">
								<ul className="w-full">
									<li className="cursor-pointer p-1 hover:text-orange-500 border-b border-orange-500"><Link className="w-full block" to="/about" onClick={this.closeMenu}>{t('footer.about')}</Link></li>
									<li className="cursor-pointer p-1 hover:text-orange-500 border-b border-orange-500"><Link className="w-full block" to="/contacts" onClick={this.closeMenu}>{t('footer.contacts')}</Link></li>
									<li className="cursor-pointer p-1 hover:text-orange-500 border-b border-orange-500"><Link className="w-full block" to="/faq" onClick={this.closeMenu}>{t('footer.help')}</Link></li>
									<li className="cursor-pointer p-1 hover:text-orange-500 border-b border-orange-500"><Link className="w-full block" to="/eats" onClick={this.closeMenu}>{t('footer.eats')}</Link></li>
								</ul>
								<div className="py-3">
									<b className="text-orange-500"> {t('social.follow')} </b>
									<div className="flex mt-2">
										<a href="http://www.facebook.com/sendeatpt" target="_blank" rel="noopener noreferrer" className="mr-4">
											<FacebookIcon className="hover:text-orange-500 stroke-current" />
										</a>
										<a href="http://www.instagram.com/sendeat_pt" target="_blank" rel="noopener noreferrer" className="mr-4">
											<InstagramIcon className="hover:text-orange-500 stroke-current"/>
										</a>
									</div>
								</div>
							</div>
						) : null
					}
				</div>
			</div>
		)
	}
}

export default withTranslation() (Menu)
