import {put, call, select, takeLatest, takeEvery} from 'redux-saga/effects'
import {alertConstants, orderingConstants} from '../_constants'
import {FAILED, REQUESTED, SUCCEEDED} from '../helpers/constants.helper'
import {partnersService} from '../_services'

function* getSchedulesSaga({partner_id}) {
	try {
		const location = yield select(({ordering: {location}}) => location)
		if (undefined === location.lat || undefined === location.lng) {
			yield put({type: orderingConstants.SCHEDULES[SUCCEEDED], schedules: [], partner_id})
			return;
		}
		const schedules = yield call(partnersService.getRestaurantSchedule, partner_id, location)
		yield put({type: orderingConstants.SCHEDULES[SUCCEEDED], schedules, partner_id})
	} catch(error) {
		yield put({type: orderingConstants.SCHEDULES[FAILED]})
		yield put({type: alertConstants.ERROR, message: String(error)})
	}
}

function* getMealsSaga({partner_id}) {
	try {
		const meals = yield call(partnersService.getRestaurantMeals, partner_id)
		yield put({type: orderingConstants.MEALS[SUCCEEDED], meals, partner_id})
	} catch (error) {
		yield put({type: orderingConstants.MEALS[FAILED]})
		yield put({type: alertConstants.ERROR, message: String(error)})
	}
}

function* getSupplementsSaga({partner_id, meal_id}) {
	try {
		const supplements = yield call(partnersService.getMealSupplements, partner_id, meal_id)
		yield put({type: orderingConstants.SUPPLEMENTS[SUCCEEDED], supplements, meal_id})
	} catch (error) {
		yield put({type: orderingConstants.SUPPLEMENTS[FAILED]})
		yield put({type: alertConstants.ERROR, message: String(error)})
	}
}

function* getPartnerSaga({nickname}) {
	try {
		const restaurant = yield call(partnersService.getRestaurantByNickname, nickname)
		yield put({type: orderingConstants.RESTAURANT[SUCCEEDED], restaurant})
	} catch (error) {
		yield put({type: orderingConstants.RESTAURANT[FAILED]})
		yield put({type: alertConstants.ERROR, message: String(error)})
	}
}

function* searchPartnersSaga({address}) {
	try {
		const {location, partners, tags, group_zone_info} = yield call(partnersService.searchRestaurants, address)
		yield put({
			type: orderingConstants.RESTAURANTS[SUCCEEDED],
			location,
			restaurants: partners,
			tags: tags,
			group_zone_info: group_zone_info,
			nicknames: partners.reduce((obj, restaurant, index) => ({...obj, [restaurant.nickname]: index}), {})
		})
	} catch (error) {
		if (error.status) {

			let search_error
			switch (error.status) {
				case 404:
					search_error = error.data && error.data.error
					break
				default:
					search_error = null
			}

			yield put({type: orderingConstants.RESTAURANTS[FAILED], error: search_error})
		} else {
			yield put({type: orderingConstants.RESTAURANTS[FAILED]})
			yield put({type: alertConstants.ERROR, message: String(error)})
		}
	}
}

function* getCategoriesSaga({market_id}) {
	try {
		const categories = yield call(partnersService.getMarketCategories, market_id)
		yield put({type: orderingConstants.CATEGORIES[SUCCEEDED], categories})
	} catch (error) {
		yield put({type: orderingConstants.CATEGORIES[FAILED]})
		yield put({type: alertConstants.ERROR, message: error.message || String(error)})
	}
}

function* searchMarketsSaga({address}) {
	try {
		const {location, market} = yield call(partnersService.searchMarkets, address)
		yield put({
			type: orderingConstants.MARKETS[SUCCEEDED],
			location,
			market
		})
		yield put({type: orderingConstants.CATEGORIES[REQUESTED], market_id: market.id})
	} catch (error) {
		if (error.status) {

			let search_error = null
			switch (error.status) {
				case 404:
					search_error = error.data && error.data.error
					break
				default:
					search_error = null
			}

			yield put({type: orderingConstants.MARKETS[FAILED], error: search_error})
		} else {
			yield put({type: orderingConstants.MARKETS[FAILED]})
			yield put({type: alertConstants.ERROR, message: String(error)})
		}
	}
}

/*
 *	--------------
 *	-- Watchers --
 *	--------------
 */

export function* watchSchedulesFetch() {
	yield takeEvery(orderingConstants.SCHEDULES[REQUESTED], getSchedulesSaga)
}

export function* watchMealsFetch() {
	yield takeEvery(orderingConstants.MEALS[REQUESTED], getMealsSaga)
}

export function* watchSupplementsFetch() {
	yield takeEvery(orderingConstants.SUPPLEMENTS[REQUESTED], getSupplementsSaga)
}

export function* watchRestaurantFetch() {
	yield takeEvery(orderingConstants.RESTAURANT[REQUESTED], getPartnerSaga)
}

export function* watchRestaurantsSearch() {
	yield takeEvery(orderingConstants.RESTAURANTS[REQUESTED], searchPartnersSaga)
}

export function* watchCategoriesFetch() {
	yield takeLatest(orderingConstants.CATEGORIES[REQUESTED], getCategoriesSaga)
}

export function* watchMarketsSearch() {
	yield takeLatest(orderingConstants.MARKETS[REQUESTED], searchMarketsSaga)
}
