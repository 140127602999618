import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import Button from '../Button'
import {cartConstants} from '../../_constants'

const EmptyCartConfirmation = ({empty_confirmation, partner_id, restaurants, market, confirmCartEmpty, notConfirmCartEmpty}) => (
	<Modal open={empty_confirmation} onClose={notConfirmCartEmpty} containerClassName="p-2" smFullPage={false}>
		<h3 className="text-xl font-bold text-orange-500">Criar um novo pedido?</h3>
		<p className="text-gray-700">
		O teu pedido atual contem artigos de { (restaurants[partner_id] ? restaurants[partner_id] : market.id === partner_id ? market : {}).name }.
		Cria um novo pedido para adicionar artigos de { (restaurants[empty_confirmation] ? restaurants[empty_confirmation] : market.id === empty_confirmation ? market : {}).name }.
		</p>
		<Button color="orange" className="mt-2" onClick={confirmCartEmpty}>Novo Pedido</Button>
	</Modal>
)

EmptyCartConfirmation.propTypes = {
	empty_confirmation: PropTypes.number,
	partner_id: PropTypes.number,
	restaurants: PropTypes.object,
	market: PropTypes.object,
	confirmCartEmpty: PropTypes.func,
	notConfirmCartEmpty: PropTypes.func
}

export default connect(
	({cart: {empty_confirmation, partner_id}, ordering: {restaurants, market}, confirmCartEmpty, notConfirmCartEmpty}) => ({
		empty_confirmation, 
		partner_id, 
		restaurants: restaurants.reduce((obj, restaurant) => ({...obj, [restaurant.id]: restaurant}), {}),
		market: market || {}, 
		confirmCartEmpty, 
		notConfirmCartEmpty
	}),
	dispatch => ({
		confirmCartEmpty: () => dispatch({type: cartConstants.EMPTY_CONFIRMED}),
		notConfirmCartEmpty: () => dispatch({type: cartConstants.EMPTY_NOT_CONFIRMED})
	})
)(EmptyCartConfirmation)
