import rootSaga from '../../_sagas'
import { persistenceSubscriber } from './persistence'
import {
	handleSessionExpired,
	loadAuth,
	loadState
} from '../localStorage'

let configureStore
if (process.env.NODE_ENV === 'production') {
	configureStore = require('./store.prod').default
} else {
	configureStore = require('./store.dev').default
}

// load state from localStorage
let persistedState
let error
try {
	persistedState = loadState()
} catch (state) {
	error = true
	persistedState = state
}
const authentication = loadAuth()

// create store
const store = configureStore({...persistedState, authentication})

store.runSaga(rootSaga)
store.subscribe(persistenceSubscriber(store))

if (error) {
	handleSessionExpired(store.dispatch)
}

store.error = error

export default store