import React from 'react'
import {Link} from '../Utilities/preloading'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {userConstants} from '../../_constants'
import {ReactComponent as AcIcon} from '../../svg/ac.svg'
import {isAuthenticated} from '../../_services/authentication.service'
import {withTranslation} from 'react-i18next'

class ClientMenu extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			open: false
		}

		this.handleClick= this.handleClick.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
		this.closeMenu = this.closeMenu.bind(this)

	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside, false)
	}

	logout() {
		this.props.logout()
		this.setState({open: false})
	}

	handleClick() {
		const {open} = this.state
		this.setState({open: !open})
	}

	handleClickOutside(e) {
		const {authentication} = this.props
		const {open} = this.state

		if (isAuthenticated(authentication) && open) {
			if (!this.node.contains(e.target))
				this.setState({open: false})
		}
	}

	closeMenu() {
		this.setState({open: false})
	}

	render() {

		const {authentication, t} = this.props
		const {open} = this.state

		if (!isAuthenticated(authentication)) {
			return (
				<Link to="/login" className="cursor-pointer text-white hover:text-orange-500 flex mx-1 sm:mx-3">
					<AcIcon className="h-5 mr-1 fill-current" alt="client menu icon"/>
					<b className="hidden md:block">{t('header_login')} {t('or')}  {t('header_register')} </b>
				</Link>
			)
		}
		return (
			<div className="h-full" ref={node => this.node = node}>
				<div className="flex items-center h-full">
					<span className={`cursor-pointer text-white hover:text-orange-500 flex mx-1 sm:mx-3`} onClick={this.handleClick}>
						<AcIcon className="h-5 mr-1 fill-current" alt="client menu icon"/>
						<b className="hidden md:block">{t('client_menu.greeting')} {authentication.user.name}!</b>
					</span>
				</div>
				<div className="relative">
					{
						open ? (
							<div className="fixed top-auto right-0 left-0 md:right-auto sm:left-auto bg-purple-500 text-white p-3 shadow">
								<ul className="whitespace-no-wrap">
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/" onClick={this.closeMenu}>{t('client_menu.home')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/referrals" onClick={this.closeMenu}>{t('client_menu.referrals')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/eats" onClick={this.closeMenu}>{t('client_menu.eats')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/orders" onClick={this.closeMenu}>{t('client_menu.orders')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/personal-data" onClick={this.closeMenu}>{t('client_menu.personal_data')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/change-password" onClick={this.closeMenu}>{t('client_menu.change_password')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500 border-b border-orange-500">
										<Link className="w-full block" to="/client-area/credit-card" onClick={this.closeMenu}>{t('client_menu.credit_card')}</Link>
									</li>
									<li className="cursor-pointer p-1 pr-3 hover:text-orange-500" onClick={this.logout.bind(this)}>{t('client_menu.logout')}</li>
								</ul>
							</div>
						) : null
					}
				</div>
			</div>
		)
	}
}

ClientMenu.propTypes = {
	authentication: PropTypes.object,
	logout: PropTypes.func
}

export default withTranslation()(connect(
	(state) => ({
		authentication: state.authentication
	}),
	dispatch => ({
		logout: () => dispatch({type: userConstants.LOGOUT})
	})
)(ClientMenu))
