import * as userService from './user.service'
import * as partnersService from './partners.service'
import * as authService from './authentication.service'
import * as ordersService from './order.service'

export {
	userService,
	partnersService,
	authService,
	ordersService
}
