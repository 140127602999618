import {all} from 'redux-saga/effects'
import {
	watchAddressesRequest,
	watchBillingsRequest,
	watchCardsRequest,
	watchLoginRequest,
	watchSaveAddress,
	watchUserStoreFrontRequest, 
	watchUserPhoneVerificationRequest, 
	watchVouchersRequest,
	watchReferralRequest,
	watchSocialLoginRequest
} from './user.sagas'
import {
	watchCategoriesFetch,
	watchMarketsSearch,
	watchMealsFetch,
	watchRestaurantFetch,
	watchRestaurantsSearch,
	watchSchedulesFetch,
	watchSupplementsFetch
} from './ordering.sagas'
import {watchAlert} from './alert.saga'
import {
	watchAddCartLine, 
	watchRemoveCartLine, 
	watchUpdateCartLine,
	watchOrderDeliveryDate,
	watchSetOrder,
	watchTipChange
} from './cart.saga'

export default function* root() {
	yield all([
		watchAlert(),
		watchLoginRequest(),
		watchSocialLoginRequest(),

		/* user sagas */
		watchAddressesRequest(),
		watchSaveAddress(),
		watchCardsRequest(),
		watchBillingsRequest(),
		watchUserStoreFrontRequest(),
		watchUserPhoneVerificationRequest(),
		watchVouchersRequest(),
		watchReferralRequest(),

		/* partner sagas */
		watchRestaurantsSearch(),
		watchMealsFetch(),
		watchSupplementsFetch(),
		watchRestaurantFetch(),
		watchSchedulesFetch(),
		watchMarketsSearch(),
		watchCategoriesFetch(),

		/* cart sagas */
		watchAddCartLine(),
		watchUpdateCartLine(),
		watchRemoveCartLine(),
		watchOrderDeliveryDate(),
		watchSetOrder(),
		watchTipChange()
	])
}
