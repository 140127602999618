import {callApi} from './api.service'
import {userConstants} from '../_constants'
import {getUser} from './user.service'

export const getUserInfo = getUser

export const login = (username, password) => {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify({
			grant_type: 'password',
			client_id: process.env.REACT_APP_API_CLIENT_ID,
			client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
			username,
			password,
			scope: ''
		})
	}

	return callApi('/oauth/token', requestOptions)
		.then((tokens) => 
			getUserInfo({'Authorization': `Bearer ${tokens.access_token}`})
				.then(user => ({user, tokens}))
		)
		.then(({user, tokens}) => ({...user, ...tokens, timestamp: (new Date()).getTime()}))
}

export const socialLogin = (provider, userFields, tokens = {}) =>
	callApi('/callback', {
		method: 'POST',
		body: JSON.stringify({
			provider,
			...userFields,
			...tokens
		})
	})
		.then((tokens) => 
			getUserInfo({'Authorization': `Bearer ${tokens.access_token}`})
				.then(user => ({user, tokens}))
		)
		.then(({user, tokens}) => ({...user, ...tokens, timestamp: (new Date()).getTime()}))

export const register = (user_info) =>
	callApi('/register', {
		method: 'POST',
		body: JSON.stringify({
			client_id: process.env.REACT_APP_API_CLIENT_ID,
			client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
			...user_info 
		})
	}, {errorDetailed: true})

export const emailResetPassword = (email) =>
	callApi('/password/email', {
		method: 'POST',
		body: JSON.stringify({
			email
		})
	})

export const logout = (dispatch) => dispatch({type: userConstants.LOGOUT})

export const isAuthenticated = (authentication) => {
	const {user = {}} = authentication
	return !!user.access_token
}
