import {createRequestTypes} from '../helpers/constants.helper'

export const orderingConstants = {
	RESTAURANTS: createRequestTypes('ORDERING_RESTAURANTS'),
	RESTAURANT: createRequestTypes('ORDERING_RESTAURANT'),
	MEALS: createRequestTypes('ORDERING_MEALS'),
	SUPPLEMENTS: createRequestTypes('ORDERING_SUPPLEMENTS'),

	MARKETS: createRequestTypes('ORDERING_MARKETS'),
	CATEGORIES: createRequestTypes('ORDERING_CATEGORIES'),

	SCHEDULES: createRequestTypes('ORDERING_SCHEDULES'),

	SET_CURRENT_ADDRESS: 'ORDERING_SET_CURRENT_ADDRESS',
	SET_SEARCH_TYPE: 'ORDERING_SET_SEARCH_TYPE',

	SEARCH_TYPE_RESTAURANTS: 'restaurants',
	SEARCH_TYPE_MARKETS: 'markets'
}
