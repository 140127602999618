import React from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import {
    Link as RouterLink,
    Redirect as RouterRedirect,
    matchPath,
    useHistory,
} from 'react-router-dom'
import routes from '../../routes'

const findComponentForRoute = path => {
    const matchingRoute = routes.find(route =>
        matchPath(path, {
            path: route.path,
            exact: route.exact,
        })
    )
    return matchingRoute ? matchingRoute.component : null
}

export const preloadRoute = route =>
    new Promise((resolve, reject) => {
        NProgress.start()
        const component = findComponentForRoute(route.split('?')[0])

        if (component && component.preload) {
            component
                .preload()
                .then(() => {
                    resolve()
                    NProgress.done()
                })
                .catch(() => {
                    reject()
                    NProgress.done()
                })
        } else {
            reject()
            NProgress.done()
        }
    })

export const goTo = (history, to) => {
    preloadRoute(to)
        .then(() => history.push(to))
        .catch(() => history.push(to))
}

export const Link = ({ to, onClick, ...props }) => {
    const history = useHistory()

    const handleClick = React.useCallback(
        e => {
            e.preventDefault()

            let _default = true
            if (onClick) {
                const preventDefault = () => (_default = false)
                e.preventDefault = preventDefault
                onClick(e)
            }

            if (_default) {
                goTo(history, to)
            }
        },
        [to, onClick, history]
    )

    return <RouterLink to={to} onClick={handleClick} {...props} />
}

Link.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]),
    onClick: PropTypes.func,
}

export const Redirect = ({ to, ...props }) => {
    const [preLoaded, setPreLoaded] = React.useState(false)

    const handleRedirect = React.useCallback(() => {
        if (preLoaded) return

        preloadRoute(to)
            .then(() => setPreLoaded(true))
            .catch(() => setPreLoaded(true))
    }, [preLoaded, to])

    React.useEffect(() => {
        handleRedirect()
    }, [handleRedirect])

    return preLoaded ? <RouterRedirect to={to} {...props} /> : null
}

Redirect.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]),
}

export const Fallback = () => {
    React.useEffect(() => {
        NProgress.inc()
        return () => {
            NProgress.done()
        }
    }, [])

    return <div />
}
