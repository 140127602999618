import {select, take, call, race, put, takeLatest, takeEvery} from 'redux-saga/effects'
import {cartConstants, orderingConstants} from '../_constants'
import {REQUESTED, SUCCEEDED} from '../helpers/constants.helper'
import { ordersService } from '../_services'

function* createOrUpdateOrder({partner_id, delivery_date, lines}) {
	const { cart, ordering } = yield select(({cart, ordering}) => ({cart, ordering}))
	
	const { search_address, address } = ordering
	const { order_id, tip } = cart

	const formattedLines = lines.map(({product_id, qty, sublines, substitutes}) =>({product_id, qty, sublines, substitutes}))

	try {
		let order

		if (order_id) {
			order = yield call(ordersService.updateOrderLines, order_id, {lines: formattedLines}, cart.order_token)
		} else {
			order = yield call(ordersService.createOrder, {
				partner_id,
				tip,
				...(address.id ? {address_id: address.id} : {formatted_address: search_address}),
				lines: formattedLines,
				...(delivery_date ? {delivery_date} : {}),
				obs: {
					restaurant: cart.observations,
					sender: cart.address_observations
				}
			})
		}
		
		yield put({ type: cartConstants.SET_ORDER, order })

	} catch (e) {
		console.error(e)
		if (e === 'Order isn\'t on the right status')
			yield put({type: cartConstants.RESET_CART})

		throw Error('Error creating/updating order!')
	}

}

function* confirmCartResetSaga(partner_id) {
	yield put({type: cartConstants.SHOW_EMPTY_CONFIRMATION, partner_id})

	const { yes } = yield race({
		yes: take(cartConstants.EMPTY_CONFIRMED),
		no: take(cartConstants.EMPTY_NOT_CONFIRMED)
	})

	yield put({type: cartConstants.HIDE_EMPTY_CONFIRMATION})

	return !!yes
}

// eslint-disable-next-line no-unused-vars
function* addCartProductSaga({type, line, ...action}) {
	const { cart, ordering: { restaurants, market } } = yield select(({cart, ordering}) => ({cart, ordering}))

	const isNewOrder = cart.partner_id !== action.partner_id

	let partner, partner_type
	if (market && market.id === action.partner_id) {
		partner = {...market}
		partner_type = orderingConstants.SEARCH_TYPE_MARKETS
	} else {
		partner = restaurants.find(partner => partner.id === action.partner_id)
		partner_type = orderingConstants.SEARCH_TYPE_RESTAURANTS
	}
	
	const {delivery_time, delivery_date: partnerDeliveryDate, open} = partner

	if (cart.partner_id && isNewOrder) {
		const confirmed = yield call(confirmCartResetSaga, action.partner_id)

		if (!confirmed)	
			return
		
		yield put({type: cartConstants.RESET_CART})
	}

	const lines = yield select(({cart: {lines}}) => lines)
	const delivery_date = open ? null : partnerDeliveryDate
	const options = {
		line, 
		partner_type, 
		delivery_date, 
		lines: [...lines, line],
		partner_id: action.partner_id, 
		delivery_duration: delivery_time, 
	}

	try {
		yield createOrUpdateOrder(options)
	} catch (e) {
		if (e.message === 'Order isn\'t on the right status')
			yield put({type: cartConstants.RESET_CART})
		return
	}
	yield put({type: cartConstants.ADD_LINE[SUCCEEDED], ...options})

}

function* updateCartLineSaga(action) {

	if (action.line.qty <= 0)
		yield put({type: cartConstants.REMOVE_LINE, line_id: action.line.id})
	else {
		const options = yield select(({cart: {partner_id, delivery_date, lines, tip}}) => ({partner_id, delivery_date, lines, tip}))
		try {
			yield createOrUpdateOrder(options)
		} catch (e) {return}
	}
}

function* removeCartLineSaga() {

	const options = yield select(({cart: {partner_id, delivery_date, lines, tip}}) => ({partner_id, delivery_date, lines, tip}))

	try {
		yield createOrUpdateOrder(options)
	} catch (e) {
		if (e.message === 'Order isn\'t on the right status')
			yield put({type: cartConstants.RESET_CART})
		return
	}

	if (options.lines.length <= 0)
		yield put({type: cartConstants.RESET_CART})
}

function* setDeliveryDateSaga({delivery_date}) {
	const order_id = yield select(({cart: {order_id}}) => order_id)

	try {
		yield call(ordersService.updateOrderTime, order_id, {delivery_date})
	} catch(e) {
		console.error(e)
	}
}

function* onTipChangeSaga() {
	const {order_id, tip} = yield select(({cart: {tip, order_id}}) => ({tip, order_id}))

	try {
		const order = yield call(ordersService.updateOrder, order_id, {tip})
		yield put({type: cartConstants.SET_ORDER, order})
	} catch (e) {
		console.error(e)
	}
}

function* setOrderSaga({order}) {

	if (!order.public_id) {
		yield put({type: cartConstants.RESET_CART})
		return
	}
}

/*
 *	--------------
 *	-- Watchers --
 *	--------------
 */

export function* watchAddCartLine() {
	yield takeLatest(cartConstants.ADD_LINE[REQUESTED], addCartProductSaga)
}

export function* watchUpdateCartLine() {
	yield takeLatest(cartConstants.UPDATE_LINE, updateCartLineSaga)
}

export function* watchRemoveCartLine() {
	yield takeLatest(cartConstants.REMOVE_LINE, removeCartLineSaga)
}

export function* watchOrderDeliveryDate() {
	yield takeLatest(cartConstants.SET_DELIVERY_DATE, setDeliveryDateSaga)
}

export function* watchTipChange() {
	yield takeLatest([cartConstants.INCREASE_TIP, cartConstants.DECREASE_TIP], onTipChangeSaga)
}

export function* watchSetOrder() {
	yield takeEvery(cartConstants.SET_ORDER, setOrderSaga)
}
