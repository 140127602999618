import {createRequestTypes} from '../helpers/constants.helper'

export const cartConstants = {

	ADD_LINE: createRequestTypes('CART_ADD_LINE'),
	REMOVE_LINE: 'CART_REMOVE_LINE',
	UPDATE_LINE: 'CART_UPDATE_LINE',
	SET_LINES: 'CART_SET_LINES',

	RESET_CART: 'CART_RESET',

	SHOW_EMPTY_CONFIRMATION: 'CART_SHOW_EMPTY_CONFIRMATION',
	HIDE_EMPTY_CONFIRMATION: 'CART_SHOW_EMPTY_CONFIRMATION',

	EMPTY_CONFIRMED: 'CART_EMPTY_CONFIRMED',
	EMPTY_NOT_CONFIRMED: 'CART_EMPTY_NOT_CONFIRMED',

	SET_ORDER: 'CART_SET_ORDER',

	SET_DELIVERY_DATE: 'CART_SET_DELIVERY_DATE',
	SET_OBSERVATIONS: 'CART_SET_OBSERVATIONS',
	SET_ADDRESS_OBSERVATIONS: 'CART_SET_ADDRESS_OBSERVATIONS',

	SET_DELIVERY_ADDRESS: 'CART_SET_DELIVERY_ADDRESS',

	SET_ATM_REFERENCE: 'CART_SET_ATM_REFERENCE',
	SET_PAYMENT_METHOD: 'CART_SET_PAYMENT_METHOD',
	SET_PAYMENT_ARGS: 'CART_SET_PAYMENT_ARGS',
	SET_WAITING_PAYMENT: 'CART_SET_WAITING_PAYMENT',

	SET_BILLING_ID: 'CART_SET_BILLING_ID',
	SET_VOUCHER: 'CART_SET_VOUCHER',
	SET_RATES: 'CART_SET_RATES',
	SET_EATS: 'CART_SET_EATS',
	SET_TIP: 'CART_SET_TIP',

	INCREASE_TIP: 'CART_INCREASE_TIP',
	DECREASE_TIP: 'CART_DECREASE_TIP',

	SET_COMPONENT_STATE: 'CART_SET_COMPONENT_STATE',
	TOGGLE_VISIBILITY: 'CART_TOGGLE_VISIBILITY',

}
